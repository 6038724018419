import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Layout({ children }) {
  return (
    <Fragment>
      <div>
        {children}
      </div>
      <ToastContainer />
    </Fragment>
  );
}

export default Layout;
