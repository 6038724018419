import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";

const MediaCard = ({ data, margin }) => {
  return (
    <Card
      className="homeBg"
      sx={{
        marginLeft: { margin },
        maxWidth: 300,
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        height: 480,
        minHeight: 250,
        transform: "scale(1)",
        transition: "0.3s",
        border: "1.105px solid #0ebfcca6",
        boxShadow: "0px 8px 15px -3px #0ebfcca6",
        "&:hover": {
          transform: "scale(1.05)",
          cursor: "pointer",
        },
      }}
    >
      <CardMedia
        component="img"
        height="240"
        image={data.image}
        alt="Random Image"
      />
      <CardContent
        sx={{
          flex: '1 0 auto',
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: { xs: 2, sm: 3, md: 4 }, // Responsive padding to adjust content spacing
        }}
      >
        <Box sx={{ mb: 2 }}> {/* Margin bottom for spacing */}
          <Typography
            gutterBottom
            variant="subtitle2"
            color="white"
            component="div"
          >
            {data.subheading}
          </Typography>
          <Typography 
            gutterBottom 
            variant="h5" 
            color="white" 
            component="div"
            sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' } }} // Adjust font size based on screen size
          >
            {data.heading}
          </Typography>
        </Box>
        <Box display="flex" mt={2} alignItems="flex-start">
          <Avatar
            sx={{ width: 34, height: 34, mr: 1 }}
            src={data.avatar}
            alt="Profile Image"
          />
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="white">
              {data.avatarheading}
            </Typography>
            <Typography variant="caption" color="white">
              {data.read}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MediaCard;
