import React, { useEffect, useRef, useState } from 'react';

const Privacy = () => {
  const scrollToElementRef = useRef(null);

  useEffect(() => {
    const hashValue = window.location.hash;
    if (scrollToElementRef.current && hashValue) {
      scrollToElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
    return (
    <section className="privacySec sec-gapTop sec-gapBottom text-center" id="privacyp" ref={scrollToElementRef}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-10">
          <h3>Privacy and Terms of Service</h3>
          <p>
            At TherapyWithAI.com, we place the utmost importance on respecting
            and safeguarding your privacy. We understand the sensitive nature of
            therapy and assure you that your conversations and personal
            information are treated with the highest level of confidentiality. Our application accesses only your email address to identify your account, and we do not access, use, store, or share any other Google user data.
          </p>
          </div>
       <div className='col-lg-6 mb-2'> 
          <div className="privacy-box text-center mt-lg-5 mt-md-4 mt-3 h-100">
          <h4 className='text-white'>Privacy</h4>
            <p>
              Our platform is built on a foundation of trust and security,
              ensuring that your data is not just secure but also never sold or
              shared with third parties. Utilizing state-of-the-art encryption
              and stringent data protection protocols, we provide a secure
              environment where you can freely express yourself without any
              concerns about privacy breaches. Your peace of mind is our
              priority, and we are committed to maintaining a safe and private
              space for your mental health journey.
            </p>
          </div>
        </div>
        <div className='col-lg-6 mb-2'> 
          <div className="privacy-box text-center mt-lg-5 mt-md-4 mt-3 h-100">
          <h4 className='text-white'>Terms of Service</h4>
            <p>
            By accessing our AI therapy services, you agree to use this platform responsibly and ethically. 
            The advice and therapeutic support provided here are not substitutes for professional medical advice, 
            diagnosis, or treatment. Always seek the advice of your physician or other qualified health providers with 
            any questions you may have regarding a medical condition. Never disregard professional medical advice or 
            delay in seeking it because of something you have read on this website. You are responsible for ensuring that 
            your use of the platform does not infringe upon any local laws or regulations. Please remember that any actions 
            taken based on advice or information received through TherapyWithAI.com are at your sole discretion and risk.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};




export default Privacy;
