import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useLoader } from "../../context/LoaderContext";
import { Card, CardMedia, Typography, CardContent, Avatar, Box, Chip } from '@mui/material';
import { useParams } from "react-router-dom";
import { cardData } from "./data";
import Showdown from 'showdown';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useViewport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile;
};

function cleanTitle(title) {
  return title.replace(/\?$/, ''); // Removes a trailing '?' if it exists
}


const BlogDetail = () => {
  const { startLoading, stopLoading } = useLoader();
  const { slug  } = useParams();
  function deslugify(slug) {
    return slug.replace(/-/g, ' ');
  }

  const title = deslugify(slug);

  console.log("title: " + title)
  const decodedTitle = decodeURIComponent(title);
  console.log("Decoded title: " + decodedTitle)
  const isMobile = useViewport(); // Use the custom hook here

  useEffect(() => {
    startLoading();
    setTimeout(() => {
      stopLoading();
    }, 1000);
  }, []);


  const data = cardData.find((ele) => cleanTitle(ele.heading.toLowerCase()) == title.toLowerCase());

  // Initialize Showdown Converter
  const converter = new Showdown.Converter();
  const description = converter.makeHtml(data.description);


  return (
    <>
     <Helmet>
       <title>{data?.heading}</title> {/* Dynamically set title */}
        <meta name="description" content={data?.subheading} /> {/* Dynamically set description */}
     </Helmet>
      <div className="page-bg chat">
        <Header />
        <div className="container">
          <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
            justifyContent: 'center',
            p: 4,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}>
            <CardMedia
              component="img"
              height="600"
              image={isMobile ? data.imageMobile : data.image} // Use the hook's return value
              alt="MUI X v7.0.0"
            />
            <CardContent
              sx={{
                color: 'white',
                '& > div': { // Targets the direct div child
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }
              }}
            >
              <div>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  color='white'
                  component="div"
                >
                  {data.subheading}
                </Typography>
                {/* <Typography gutterBottom variant="h5" component="div">
                  {data.heading}
                </Typography> */}
              </div>
              <Box display="flex" mt={2} alignItems="flex-start">
                <Avatar
                  sx={{ width: 34, height: 34, mr: 1 }}
                  src={data.avatar}
                  alt="Profile Image"
                />
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle2" color='white'>
                    {data.avatarheading}
                  </Typography>
                  <Typography variant="caption" color='white'>
                    {data.read}
                  </Typography>
                </Box>

              </Box >
              <div
                style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'Times New Roman, Times, serif' }} // CSS for text wrapping
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </CardContent>
          </Card>

        </div>
      </div>
    </>
  );
};
export default BlogDetail;
