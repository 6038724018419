import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Profile from './Profile';
import useAuth from "../Hooks/useAuth";
import Footer from './home/Footer';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Header = ({ profileImage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleChatClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
  
    // Check if the pathname matches '/chat' regardless of any query parameters
    if (location.pathname === '/chat') {
      // window.location.href = '/chat'; // This will navigate to /chat without query params
      // OR to keep the query params while refreshing, use:
      window.location.reload();
    } else {
      navigate('/chat'); // Navigate to the chat page
    }
  };

  const authCtx = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  return (

    <header className="main-header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="headerRow">
              <span className="click">
                <FontAwesomeIcon icon={icon({ name: "bars" })} color="#1C9BBF" onClick={() => setIsOpen(true)} />
                {/* <i className="fa fa-bars" /> */}
              </span>
              <div className={`main_menu ${isOpen ? 'mobile_open' : 'mobile_close'}`} style={{ width: isOpen ? '100%' : '0%' }}>
                <button className="closebtn" onClick={() => setIsOpen(false)} >&times;</button>
                <ul>
                {!authCtx.isLoggedIn && (
          <li className='m-login'>
            <NavLink className={({ isActive }) => isActive ? "current-menu-item" : ''} to="/login">Login</NavLink>
          </li>
        )}                  <li> <NavLink className={({ isActive }) => isActive? "current-menu-item": ''} to="/">HOME</NavLink> </li>
                  <li> <NavLink 
          to="/chat" 
          className={({ isActive }) => isActive ? "current-menu-item" : ''}
          onClick={handleChatClick}
        >
          Chat
        </NavLink> </li>
                  <li> <NavLink className={({ isActive }) => isActive? "current-menu-item": ''} to="/plans-pricing">Plans &amp; Pricing</NavLink> </li>
                  <li> <NavLink className={({ isActive }) => isActive? "current-menu-item": ''} to="/blogs">Blog</NavLink> </li>
                </ul>
             <div className='mobile-footer'>
             <Footer />
             </div>
              </div>

              <div className="logo">
                <NavLink to="/"> <img src="/assets/images/logo.png" alt="" /> </NavLink>
              </div>
              <div className="headerRight">
                {!authCtx.isLoggedIn ? (
                  <ul>
                    <li> <NavLink className={({ isActive }) => isActive? "current-menu-item": ''} to="/resgistation"><span className='block'>Sign up</span></NavLink> </li>
                    <li className='d-log'> <NavLink className={({ isActive }) => isActive? "current-menu-item": ''} to="/login">Login</NavLink> </li>
                  </ul>
                 ) : (
                  <Profile image={profileImage}/>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header >

  );
};
export default Header;


