import { useContext } from "react";
import EmailContext from "../context/emailContext";

function useEmail() {
    const context = useContext(EmailContext);
    if (!context){
        throw Error('useContext must be used inside an EmailContextProvider')
    }
    return context;
}
export default useEmail
