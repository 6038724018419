import React from 'react';

const Footer = () => {
    return (
        <footer className="main-footer">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8 col-md-10">
        <div className="ftr-social">
          <ul>
              <li> 
                <a href="https://www.instagram.com/free.ai.therapy.24.7" target='_blank' rel="noreferrer"> <img src="/assets/images/social-icon1.png" alt="" /> 
                </a> 
              </li>
            <li> 
              <a href="https://www.facebook.com/profile.php?id=61555723034323" target='_blank' rel="noreferrer"> <img src="/assets/images/social-icon2.png" alt="" /> 
              </a> 
            </li>
            <li> 
              <a href="https://www.tiktok.com/@therapywithai" target='_blank' rel="noreferrer"> <img src="/assets/images/social-icon3.png" alt="" /> 
              </a> 
            </li>
              <li> 
                <a href="https://www.linkedin.com/company/therapywithai" target='_blank' rel="noreferrer"> <img src="/assets/images/linkedin.png" alt="" /> </a> 
              </li>
              <li> 
                <a href="https://twitter.com/TherapyWithAI" target='_blank' rel="noreferrer"> <img src="/assets/images/twitter.png" alt="" /> 
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@TherapyWithAI" target='_blank' rel="noreferrer"><img src="/assets/images/youtube.png" alt="" /> 
              </a> 
            </li>
          </ul>
        </div>
        <div className="ftr-input-box">
          <button type="button" className="btn" onClick={() => window.location = 'mailto:support@therapywithai.com'}>
          Support
        </button>
        </div>
      </div>
    </div>
  </div>
</footer>

    );
};





export default Footer;
