import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "./common/Button";
import { EffectCards } from "swiper";

import "swiper/css";
import "swiper/css/effect-cards";

import { SubscriptionButtton } from "../pages/subscription/subscriptionButton";
import useApiCalls from "../Hooks/useApiCalls";
import { subsapi } from "../Shared/helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function MobileSliderAnually() {

const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  
  const api = useApiCalls();
  const navigate = useNavigate();

  const fetchSubscriptionDetails = async () => {
    try {
      const res = await api(subsapi, 'get', {});
      setSubscriptionDetails(res.data);
      setActivePlan(res.data.data.planId);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  };
  
  useEffect(() => {
    fetchSubscriptionDetails();
  }, [activePlan]);

  const getDefaultPlan = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to cancel your subscription?");
      if (!isConfirmed) {
        return;
      }
      const res = await api(subsapi, 'delete', {});
      if(res.status === 200){
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error in delete subscription:', error);
    }
  }

  return (
    <>
      <div className="plane-page mobile-slider">
        <Swiper
          effect={"cards"}
          grabCursor={false}
          preventClicks={false}
          preventClicksPropagation={false}
          modules={[EffectCards]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="tab-col">
              <h3>Free Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>0.00/Month{" "}
              </div>
              <p className="text-center m-text">Free Therapist</p>
              {activePlan ? (
                <button type='button' className="btn w-100" onClick={getDefaultPlan}>Get Default Plan</button>
              ) : (
                <Button title={"Default Plan"} className={"w-100"} />
              )}
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 10 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
          <div className="tab-col">
              <h3>Therapy Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>7.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Therapy Unlimited" 
                price="7.99" 
                planId="P-2MB80876AU170761TMXU7G2Y"
                subscriptionDetails={subscriptionDetails} />
              </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
          <div className="tab-col">
              <h3>Expert Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>12.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Expert Therapist" 
                price="12.99" 
                planId="P-3TL16085DN246563VMXKO2GI"
                subscriptionDetails={subscriptionDetails} />
              </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 25 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="tab-col">
              <h3>Expert Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>16.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Expert Unlimited" 
                price="16.99" 
                planId="P-11F22732MX5592719MXKO3IA" 
                subscriptionDetails={subscriptionDetails}/>
              </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
