import { useEffect } from "react";
import useAuth from "./useAuth";
import { axiosPrivateInstance } from "../Api";

const useAxiosPrivate = () => {
  const authCtx = useAuth();
  let omitCalls = ["/auth/"];

  const excludeUrls = (url) => {
    let calls = false;
    omitCalls.forEach((api) => {
      if (url.includes(api)) {
        calls = false;
      } else {
        calls = true;
      }
    });
    return calls;
  };

  useEffect(() => {
    let skipInterceptor = false;
    const requestIntercept = axiosPrivateInstance.interceptors.request.use(
      (config) => {
        skipInterceptor = excludeUrls(`config.url`);
        if (
          skipInterceptor &&
          authCtx.isLoggedIn &&
          !config.headers["Authorization"]
        ) {
          config.headers[
            "Authorization"
          ] = `Bearer ${authCtx?.token}`;
        }
        if(config.url === '/api/account/upload-photo'){
          config.headers['Content-Type'] = 'multipart/form-data'
        }
        if(config.url === '/api/account/profile-picture'){
          config.responseType = 'blob';
        }
        return config;
      },
      async (error) => {
        return Promise.reject(error);
      }
    );
    const responseIntercept = axiosPrivateInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        return error.response;
      }
    );
    return () => {
      axiosPrivateInstance.interceptors.request.eject(requestIntercept);
      axiosPrivateInstance.interceptors.response.eject(responseIntercept);
    };
  }, []);
  return axiosPrivateInstance;
};
export default useAxiosPrivate;
