import { useState, createContext } from "react";

const EmailContext = createContext({
    uemail: '',
    emailData: (data) => "",
    saveemail : () => "",
})

export const EmailContextProvider = ({children}) => {
    const [userEmail,setUserEmail] = useState(JSON.parse(localStorage.getItem('uemail')) ? JSON.parse(localStorage.getItem('uemail')) : "");

    const emailDataHandler = async (data) => {
        setUserEmail(data);
        localStorage.setItem('uemail',JSON.stringify(data))
    }
    const contextValues = {
        uemail:userEmail,
        emailData: emailDataHandler,
        saveemail: setUserEmail
    }

    return <EmailContext.Provider value={contextValues}>{children}</EmailContext.Provider>
}

export default EmailContext;