import React from "react";
import Header from "../../components/Header";
import InputField from "../../components/common/InputField";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signupModel } from "../../Shared/model";
import { signupschema } from "../../Validators/SignupValidator";
import useApiCalls from "../../Hooks/useApiCalls";
import { signup } from "../../Shared/helper";
import Checkbox from "../../components/common/Checkbox";
import Button from "../../components/common/Button";
import useEmail from "../../Hooks/useEmail";
import { useGoogleLogin } from '@react-oauth/google';
import useAuth from "../../Hooks/useAuth";
import { googlelogin } from "../../Shared/helper";
import { NotificationManagerError, NotificationManagerSuccess } from "../../utils/Notification";
import { useLoader } from '../../context/LoaderContext';
import { useEffect } from "react";

function Resgistation() {

  const { startLoading, stopLoading } = useLoader();
  useEffect(() => {
    startLoading();
    setTimeout(() => {
      stopLoading();
    }, 1000);
  }, []);

  const navigate = useNavigate();
  const api = useApiCalls();
  const authCtx = useAuth();
  const emailCtx = useEmail();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues: signupModel,
    validationSchema: signupschema,
    onSubmit: async(value) => {
      const res = await api(signup, 'post',{
        username: value.username,
        email: value.email,
        password: value.password
      })
      if(res.status === 201){
        NotificationManagerSuccess(res?.data?.message);
        emailCtx.emailData(value.email);
        navigate("/verify-signup-otp",{replace:true});
      }
      else{
        NotificationManagerError(res?.data?.message);
      }
    }
  })

  const glogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await api(googlelogin, 'post', {
          token: codeResponse.code,
        });
        if (res.status === 200) {
          NotificationManagerSuccess(res?.data?.message);
          authCtx.login(res.data);
          navigate("/chat", { replace: true });
        } else {
        }
      } catch (error) {
        console.error('Error making API call:', error);
      }
    },
    flow: 'auth-code',
  });


  return (
      <div className="page-bg">
      <Header />

      <div className="login-page-flex">
        <div className="login-page-flex-main w-100">
        <div className="login-page">
          <h1 className="text-center">Sign up</h1>
          <p className="text-center mb-4 mt-2">Created account to start AI Therapy</p>

          <form onSubmit={handleSubmit}>
            <InputField 
              placeholder={"Username"} 
              type={"text"} 
              name={"username"}
              id={"username"}
              iconName={"fas fa-user-circle"}
              onBlur={handleBlur} 
              onChange={handleChange} 
              value={values.username} 
              errors={errors.username} 
              touched={touched.username}
            />
            <InputField 
            placeholder={"Email"} 
            type={"text"} 
            name={"email"}
            id={"email"} 
            iconName={"fa-solid fa-envelope"}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            errors={errors.email}
            touched={touched.email}
            />
            <InputField 
            placeholder={"Password"} 
            type={"password"} 
            name={"password"}
            id={"password"}
            iconName={"fa-solid fa-lock"}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            errors={errors.password}
            touched={touched.password}
            />
            <Checkbox 
              label={" Terms of Service"}
              name={"termsandservice"} 
              id={"termsandservice"} 
              onBlur={handleBlur} 
              onChange={handleChange} 
              value={values.termsandservice} 
              errors={errors.termsandservice} 
              touched={touched.termsandservice} 
              type={"reg"}
            />
            <Button type={"submit"} title={"Sign up"} className={"w-100"} />
            <p className="text-center mt-3 mb-3">or</p>
            <button type="button" className="btn btn-gray w-100" 
                onClick={() => glogin()} >
                <img src="/assets/images/g.png" alt="" />Sign in with Google</button>
          </form>
        </div>
          <p className="text-center mb-0">Already have an account? <NavLink to="/login">Log In</NavLink></p>
        </div>
      </div>
    </div>
  )
}
export default Resgistation