import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import OTPInput, { } from "otp-input-react";
import Button from "../../components/common/Button";
import useEmail from "../../Hooks/useEmail";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { otpSchema } from "../../Validators/OTPValidator";
import { verifyForgotPassOtpModel } from "../../Shared/model";
import useApiCalls from "../../Hooks/useApiCalls";
import { forgotpassverify, resendotp } from "../../Shared/helper";

function VerefyOtp() {
    const [otp, setOtp] = useState("");
    const emailCtx = useEmail();
    const api = useApiCalls();
    const navigate = useNavigate();
    const authCtx = useAuth();
    const { errors, touched, handleSubmit, setFieldValue} = useFormik({
        initialValues: verifyForgotPassOtpModel,
        validationSchema: otpSchema,
        onSubmit: async(values) => {
            const res = await api(forgotpassverify, 'post', {
                email: emailCtx.uemail,
                verificationCode: values.verificationCode
            })
            if(res.status === 200){
                authCtx.login(res.data);
                emailCtx.emailData("");
                navigate("/new-password",{replace:true});
            }
        }
    })

    useEffect( () => {
        setFieldValue('verificationCode', otp);
    }, [otp, setFieldValue] );

    const resendOTP = async() =>{
        const res = await api(resendotp, 'post', {
            email: emailCtx.uemail
        })
        if(res.status === 200){
            emailCtx.emailData("");
            navigate("/verify-otp");
        }
    }

    return (
        <>
            <div className="page-bg">
                <Header />
                <div className="login-page-flex otp-set">
                    <div className="login-page-flex-main w-100">
                        <div className="login-page">
                            <h1 className="text-center">Verify your email</h1>
                            <p className="text-center mb-4 mt-2">Please enter the code sent to {emailCtx.uemail}</p>
                            <form onSubmit={handleSubmit}>
                            <OTPInput 
                                className="otp-otp"
                                autoFocus 
                                OTPLength={6} 
                                disabled={false} 
                                secure={false} 
                                value={otp}
                                onChange={(otp) => { setOtp(otp); }}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                                />
                               <div className="text-center mb-2">
                               { touched.verificationCode && errors.verificationCode ? (
                                <span className="error-msg">{errors.verificationCode}</span>
                                ) : null}
                               </div>
                                <Button type={"submit"} title={"Verify"} className={"w-100"} />
                            </form>
                        </div>
                        <p className="text-center resend curser-pointer mb-0" onClick={resendOTP}>Resend OTP</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VerefyOtp