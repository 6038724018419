import useAxiosPrivate from "./useAxiosPrivate";


const useApiCalls = () => {
  const apicallPrivate = useAxiosPrivate();
  const ServerCall = async (url, callmethod, data=null, options = {}) => {
      return apicallPrivate({
        url: url,
        method: callmethod,
        data,
        ...options // Spread additional options into the Axios request config
      });
  };
  return ServerCall;
};

export default useApiCalls;
