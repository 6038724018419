import './App.css';
import React, { useState, useEffect } from 'react';
import SiteRoutes from "./navigation"; 
import Layout from "./components/Layout";
import useAuth from "../src/Hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

function App() {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const [token,setToken] = useState(JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')) : null);

  const handleLogout = () => {
    authCtx.logout();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 < Date.now()) {
            handleLogout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      }
    };
    checkTokenExpiration();
  }, [token]);

  return (
    <Layout>
      <SiteRoutes />
    </Layout>
  );
}
export default App;
