import { Link } from "react-router-dom"
import { scroller } from "react-scroll";

const Checkbox = ({label,name,id,onBlur,onChange,value,errors,touched, type}) => {
  return (
    <div className="form-holder col-md-12">
    <div className="check-holder d-flex align-items-center">
      <input 
      type="checkbox"
      name={name}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      />
      {type === 'reg' ? (
        <label>
          I agree to
          { /*<Link to={'/#privacyp'}>
            {label}
          </Link> */ }
          <a href="/#privacyp">{label}</a>
        </label>
      ) : (
        <label>{label}</label>
      )}
      <br />
    </div>
    {errors && touched ? <span className="error-msg">{errors}</span> : null}
  </div>
  )
}
export default Checkbox

