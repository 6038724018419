import { Box } from "@mui/material";
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { cardData } from "../../pages/Blogs/data";
import MediaCard from "../MediaCard";
import { Link } from "react-router-dom";

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

function cleanTitle(title) {
  return title.replace(/\?$/, ''); // Removes a trailing '?' if it exists
}

const Blogsection = () => {
  const [marqueePlay, setMarqueePlay] = useState(false);
  const [translateX, setTranslateX] = useState(0);

  // Element width should match the width of each card in the Marquee
  const elementWidth = 360; // Adjust this to the actual width of your elements

  const slideNext = () => {
    setTranslateX(prev => prev - elementWidth);
    console.log(translateX)
    if(translateX==-cardData.length*elementWidth){
      setTranslateX(0)
    }
  };

  const slidePrev = () => {
    if(translateX>=0){
      return
    }
    setTranslateX(prev => prev + elementWidth);
    if(translateX==-cardData.length*elementWidth){
      setTranslateX(0)
    }
  };

  return (
    <>
      <div className="container">
      <div className="gorazd-left" onClick={slidePrev}><i className="fas fa-arrow-left" /></div>
          <div className="gorazd-right" onClick={slideNext}><i className="fas fa-arrow-right" /></div>
        <div className="articalTitle ">
          <h3>Does AI Therapy Work?</h3>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          
        </div>
        <Marquee
          style={{ height: '70vh', position: "relative" }}
          play={marqueePlay}
        >
          
          <Box
            sx={{
              display: "flex", transform: `translateX(${translateX}px)`
            }}
            onMouseEnter={() => setMarqueePlay(true)}
            onMouseLeave={() => setMarqueePlay(false)}
          >
            {cardData.map((ele, index) => {
              return (
                <Link key={index} to={`/blogs/${cleanTitle(slugify(ele.heading))}`}>
                  <MediaCard data={ele} margin="30px" />
                </Link>
              );
            })}
          </Box>
        </Marquee>
      </div>
    </>
  );
};

export default Blogsection;
