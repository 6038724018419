import axios from "axios";
import { apiBaseUrl } from "../Shared/helper";

export const axiosInstance = axios.create({
  baseURL: `${apiBaseUrl}`,
});

export const axiosPrivateInstance = axios.create({
  baseURL: `${apiBaseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});
