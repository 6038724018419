import React from "react";
import Header from "../../components/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginModel } from "../../Shared/model";
import { loginSchema } from "../../Validators/LoginValidator";
import useApiCalls from "../../Hooks/useApiCalls";
import { login as loginurl } from "../../Shared/helper";
import { googlelogin } from "../../Shared/helper";
import useAuth from "../../Hooks/useAuth";
import InputField from "../../components/common/InputField";
import Button from "../../components/common/Button";
import Checkbox from "../../components/common/Checkbox";
import { useGoogleLogin } from '@react-oauth/google';
import { NotificationManagerSuccess, NotificationManagerError } from "../../utils/Notification";
import { useLoader } from '../../context/LoaderContext';
import { useEffect } from "react";

function Login() {
  const { startLoading, stopLoading } = useLoader();
  useEffect(() => {
    startLoading();
    setTimeout(() => {
      stopLoading();
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const api = useApiCalls();
  const authCtx = useAuth();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues: loginModel,
    validationSchema: loginSchema,
    onSubmit: async(value) => {
      const res = await api(loginurl,'post',{
        email: value.email,
        password: value.password
      })
      if(res.status === 200){
        NotificationManagerSuccess(res?.data?.message);
        authCtx.login(res.data)
        navigate("/chat", {replace:true});
      }else{
        NotificationManagerError(res?.data?.message);
      }
    }
  })

 const glogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await api(googlelogin, 'post', {
          token: codeResponse.code,
        });
        if (res.status === 200) {
          NotificationManagerSuccess(res?.data?.message);
          authCtx.login(res.data);
          navigate("/chat", { replace: true });
        } else {
        }
      } catch (error) {
        console.error('Error making API call:', error);
      }
    },
    flow: 'auth-code',
  });

  return (
    <>
      <div className="page-bg">
        <Header />

        <div className="login-page-flex">
          <div className="login-page-flex-main w-100">
            <div className="login-page">
              <h1 className="text-center">Log in</h1>
              <p className="text-center mb-4 mt-2">Login to manage your account</p>
              <form onSubmit={handleSubmit}>
              <InputField 
                placeholder={"Email"} 
                type={"text"} 
                name={"email"}
                id={"email"} 
                iconName={"fa-solid fa-envelope"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                errors={errors.email}
                touched={touched.email}
                />
                <InputField 
                placeholder={"Password"} 
                type={"password"} 
                name={"password"}
                id={"password"}
                iconName={"fa-solid fa-lock"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                errors={errors.password}
                touched={touched.password}
                />
                <Checkbox 
                  label={"Remember Me"}
                  name={"rememberme"} 
                  id={"rememberme"} 
                  className="ch-lab mb-3"
                  onBlur={handleBlur} 
                  onChange={handleChange} 
                  value={values.rememberme} 
                  errors={errors.rememberme} 
                  touched={touched.rememberme} 
                  />
                <Button type={"submit"} title={"Login"} className={"w-100"} />

                <p className="text-center mt-3 mb-3">or</p>

                <button type="button" className="btn btn-gray w-100" 
                onClick={() => glogin()} >
                <img src="/assets/images/g.png" alt="" />Sign in with Google</button>
              </form>
            </div>

            <p className="text-center">Don’t you have an account? <NavLink to="/resgistation">Sign Up</NavLink></p>
            <NavLink className="text-center d-block" to="/forget-password">Forgot password?</NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
