import React from 'react'

function Button(pops) {
    const { title, type , onClick = () => { }, className = "" } = pops
    return (
        <>
            <button type={type} className={`btn ${className ?? className}`} onClick={onClick} >{title}</button>
        </>
    )
}
export default Button