import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InputField(props) {
    const { placeholder, type, name, id, iconName, onBlur, onChange, value, errors, touched } = props;
    const [passwordvisible, setPasswordvisible] = useState(false);
    return (
        <div className="form-group position-relative">
            <div className='position-relative'>
            <span className="icon-container">
                <FontAwesomeIcon icon={iconName} />
            </span>

            <input
            type={passwordvisible ? 'text' : type}
            className="form-control"
            autoComplete="off"
            placeholder={placeholder}
            name={name}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            />
            {type==='password' && <span className="password-show-hide">
                <FontAwesomeIcon icon={!passwordvisible ? 'fa fa-eye' : 'fa-eye-slash'} onClick={() => { setPasswordvisible((pre) => !pre); }} />
            </span> }
            </div>

            {errors && touched ? <span className="error-msg">{errors}</span> : ""}

        </div>
    )
}

export default InputField