import React from "react";
import Header from "../../components/Header";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/common/InputField";
import Button from "../../components/common/Button";
import { forgotschema } from "../../Validators/ForgotValidator";
import { fogotOtpModel } from "../../Shared/model";
import useApiCalls from "../../Hooks/useApiCalls";
import { forgotpassotp } from "../../Shared/helper";
import useEmail from "../../Hooks/useEmail";
import { NotificationManagerError, NotificationManagerSuccess } from "../../utils/Notification";


const ForgetPassword = () => {
  const navigate = useNavigate();
  const emailCtx = useEmail();
  const api = useApiCalls();

  const {  values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: fogotOtpModel,
    validationSchema: forgotschema,
    onSubmit: async(value) => {
      const res = await api(forgotpassotp, 'post', {
        email: value.email
      })
      if(res.status === 200){
        NotificationManagerSuccess(res?.data?.message);
        emailCtx.emailData(value.email);
        navigate('/verify-otp', {replace: true});
      }else{
        NotificationManagerError(res?.data?.message);
      }
    }
  })

    return (
      <div className="page-bg">
      <Header />
      <div className="login-page-flex">
        <div className="login-page-flex-main w-100">
        <div className="login-page">
          <h1 className="text-center">Forgot Password?</h1>
          <p className="text-center mb-4 mt-2">Please enter your registered email address to receive verification code</p>
          <form onSubmit={handleSubmit}>
            <InputField 
              placeholder={"Email"} 
              type={"email"} 
              name={"email"}
              id={"email"}
              iconName={"fa-solid fa-envelope"}
              value={values.email}
              onBlur={handleBlur} 
              onChange={handleChange}
              errors={errors.email}
              touched={touched.email}
            />
            <Button type={"submit"} title={"Send code"} className={"w-100"} />
          </form>
        </div>
        </div>
      </div>
    </div>
  );
}
export default ForgetPassword;
