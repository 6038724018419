import { AuthContextProvider } from "../context/authContext"
import { EmailContextProvider } from "../context/emailContext"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { LoaderProvider }  from "../context/LoaderContext"

const WholeSiteContextProvider = ({children}) => {
  const initialOptions = {
    'client-id': "ATq2te6_ZAoifozl0SBPR48HjtKkI5D2uWZ6rVaRoQSQ-dOxF8S4fZauVVI7Kkqk6F92vuAgQ0ES1U1v",
    currency: "USD",
    intent: "subscription",
    vault: true
  };
  return (
    <AuthContextProvider>
        <EmailContextProvider>
        <GoogleOAuthProvider clientId="463558082621-vr23u0p0q0p2j0biso7bs2rps5fjjmd1.apps.googleusercontent.com">
        <PayPalScriptProvider options={initialOptions}>
          <LoaderProvider>
          {children}
          </LoaderProvider>
        </PayPalScriptProvider>
        </GoogleOAuthProvider>
        </EmailContextProvider>
    </AuthContextProvider>
  )
}
export default WholeSiteContextProvider