import React, { useState } from "react";
import ClickAway from "./ClickAway";
import useApiCalls from "../Hooks/useApiCalls";
import { deleteChat, renameChatUpdate } from "../Shared/helper";
import { useNavigate } from "react-router-dom";
import { NotificationManagerSuccess, NotificationManagerError } from "../utils/Notification";

const ChatLeft = ({onInitiateNewChat, chatHistory, getChatHistory, chatId, setChatId, chatSegmentId, setChatSegmentId }) => {
  const api = useApiCalls();
  const navigate = useNavigate(); 
  const [renameMessage, setRenameMessage] = useState("");
  const [renamebox, setRenamebox] = useState(false);
  const [renameChatID, setRenameChatID] = useState(""); 
  const [showChatLeft, setShowChatLeft] = useState(true);
  const [showChatRight, setShowChatRight] = useState(true);

  // Convert iso format to ddmmyy
  const dateFormat = (dt) => {
    const originalDate = new Date(dt);
    const formattedDate = `${String(originalDate.getDate()).padStart(2, '0')}.
    ${String(originalDate.getMonth() + 1).padStart(2, '0')}.${originalDate.getFullYear()}`;
    return formattedDate;
  }

  // Delete chat
  const handleDeleteChat = async (chatIdToDelete) => {
    try {
      const res = await api(deleteChat + chatIdToDelete, 'delete', {});
      if (res.status === 200) {
        getChatHistory();
        if (chatIdToDelete == chatId) {
          setChatId('');
          setChatSegmentId('');
          navigate('/chat');
        }
      }
      //NotificationManagerSuccess(res?.data?.message);
    } catch (error) {
      // NotificationManagerError(res?.data?.message || "Error deleting chat.");
      console.error('Error deleting chat:', error);
    }
  };

  // Rename chat
  const handleRenameChat = (chatid, chatName) => {
    setRenamebox(true);
    setRenameChatID(chatid);
    setRenameMessage(chatName);
  };

  // Rename Update
  const onRenameUpdate = async() => {
    try {
      const res = await api(renameChatUpdate + renameChatID, 'patch', {
        newName: renameMessage
      });
      if(res.status === 200){
          setRenameChatID("")
          setRenamebox(false);
          getChatHistory();
      }
    } catch (error) {
      console.error('Error rename chat:', error);
    }
  }

  // Get chat history by segment id
  const handleChatSelect = async(sid) => {
      if (window.innerWidth <= 767) { 
        setShowChatLeft(false);
        setShowChatRight(true);
        onInitiateNewChat(); 
        navigate('/chat?id='+sid);
      }else{
        navigate('/chat?id='+sid);
      }
  }
  
  // Initaite new chat
  const initiateNewChat = () => {
      setChatId('');
      setChatSegmentId('');
    if (window.innerWidth <= 767) { 
      setShowChatLeft(false);
      setShowChatRight(true);
      onInitiateNewChat(); 
      navigate('/chat');
    }else{
      navigate('/chat');
    }
  }

  return (
    <div className="chatleft-bar">
      <div className="chat-logo d-flex align-items-center">
        <img src="/assets/images/chat-logo.png" alt="" />
        <p className="mb-0">AI Therapist</p>
      </div>
      <div className="chat-search">
        <div className="chat-search-child position-relative">
          <div className="chat-add" onClick={initiateNewChat}>
            <img src="/assets/images/add.png" alt="" />
          </div>
        </div>
      </div>

      <div className="chat-list">
        <ul className="p-0">
          { chatHistory && chatHistory.map((his, key) => (
            <li className="chat-list-wrap" key={key}> 
            <div className="hist-list-item d-flex align-items-center">
            <div className={`hit-map ${renamebox && renameChatID === his._id ? 'add-rename-box' : ''}`} onClick={() => handleChatSelect(his._id)}>
              <div className="chat-user-img">
                <img src="/assets/images/chat-user.png" alt="" />
              </div>
              { renameChatID !== his._id ? (
              <div className="chat-user-name">
                <div className="chat-date-f d-flex justify-content-between">
                  <h4>{his.name}</h4>
                  <div className="chat-date">{dateFormat(his.dateStart)}</div>
                </div>
              </div>
              ): null }
            </div>
              { renamebox && renameChatID === his._id ? (
                <div className="chat-user-name">
                  <div className="chat-date-f d-flex justify-content-between">
                    <input 
                      type="text"
                      value={renameMessage}
                      id="renameChat"
                      name="renameChat"
                      onChange={(e)=>setRenameMessage(e.target.value)}
                      className="form-control"
                    />
                    <button onClick= {onRenameUpdate} type="button" className="chat-send"><img src="/assets/images/send.png" alt="" /></button>
                  </div>
                </div>
              ): null }
                <div className="dot-click">
                  <ClickAway chatid={his._id} chatName={his.name} onDelete={handleDeleteChat} onRename={handleRenameChat} />
                </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default ChatLeft;
