import { PayPalButtons } from '@paypal/react-paypal-js';
import useApiCalls from "../../Hooks/useApiCalls";
import { subsapi } from "../../Shared/helper";
import { useEffect, useRef, useState } from 'react';
import Button from '../../components/common/Button';
import { NotificationManagerSuccess } from "../../utils/Notification";
import { useNavigate } from "react-router-dom";


export const SubscriptionButtton = ({ planId, subscriptionDetails, activePlan, setActivePlan }) => {
    const api = useApiCalls();
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [status, setStatus] = useState(null);
    const statusRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await api(subsapi, 'get', {});
          setStatus(res?.data?.data?.status);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);

    useEffect(() => {
      statusRef.current = status;
      console.log(statusRef.current);
    }, [status]);

    const createSubscription = (data, actions, plan) => {
        return actions.subscription.create({
          plan_id: plan,
          intent: 'subscription',
        });
    };


    const triggerConversionEvent = (subsId, transactionValue = 15) => {
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-11145623345/j21UCPiU-qcZELHu0sIp',
          'value': transactionValue,
          'currency': 'USD',
          'transaction_id': subsId
        });
      } else {
        console.error("gtag is not defined. This could mean that the Google Tag Manager script has not loaded yet.");
      }
    };

    const triggerTapfiliateConversion = (uniqueConversionId, conversionAmount) => {
      if (window.tap) {
          window.tap('conversion', uniqueConversionId, conversionAmount);
      } else {
          console.error("Tapfiliate is not defined. Make sure the Tapfiliate script is loaded properly.");
      }
  };
    
    const onApprove = async (data, actions) => {
      try {
        const details = await actions.subscription.get();
        const subsID = data.subscriptionID;
        // Log the current value of status
        console.log(statusRef.current);
  
        if (statusRef.current === 'ACTIVE') {
          console.log(1);
          const res = await api(subsapi, 'put', {
            subscriptionID: subsID,
          });
          setActivePlan(res.data.planId);
          NotificationManagerSuccess(res?.data?.message);
          setSubscriptionStatus('ACTIVE');
          const uniqueConversionId = subsID;
          const conversionAmount = res.data.data.planDetails.price ?? 20;
          triggerTapfiliateConversion(uniqueConversionId, conversionAmount);  
          triggerConversionEvent(subsID)
          navigate("/chat", {replace:true});
        } else {
          console.log(2);
          const res = await api(subsapi, 'post', {
            subscriptionID: subsID
          });
          setActivePlan(res.data.planId);
          NotificationManagerSuccess(res?.data?.message);
          setSubscriptionStatus('ACTIVE');
          const uniqueConversionId = subsID;
          const conversionAmount = res.data.data.planDetails.price ?? 20;
          triggerTapfiliateConversion(uniqueConversionId, conversionAmount);  
          triggerConversionEvent(subsID)
          navigate("/chat", {replace:true});
        }
      } catch (error) {
        console.error('Error in creating subscription:', error);
      }
    };
     
    const onCancel = (data, plan) => {
        console.log(`Subscription for ${plan} cancelled`, data);
    };
      
    const onError = (err, plan) => {
        console.error(`Subscription for ${plan} error`, err);
    };

    useEffect(() => {
      const fetchSubscriptionStatus = async () => {
        if (subscriptionDetails) {
          try {
            setSubscriptionStatus(subscriptionDetails?.data?.status);
          } catch (error) {
            console.error('Error fetching subscription status:', error);
          }
        }
      };
      fetchSubscriptionStatus();
    }, [subscriptionDetails, planId]);
  
    return (
      <div>
        {subscriptionStatus === 'ACTIVE' && activePlan === planId ? (
          <Button title={"Active Plan"} className={"w-100"} />
        ) : (
          <div>
          <div className="button-pay">
          <PayPalButtons
            style={{
              layout: 'horizontal',
              color: 'silver',
              shape: 'pill',
              label: 'paypal',
              tagline: false,
            }}
            createSubscription={(data, actions) => createSubscription(data, actions, planId)}
            onApprove={(data, actions) => onApprove(data, actions, planId)}
            onCancel={(data) => onCancel(data, planId)}
            onError={(err) => onError(err, planId)}
          /> 
          </div>
          <Button title={"Get Started"} className={"w-100"} />
          </div>
        )}
      </div>
    );
  }