
// signup
export const signupModel = {
    username: '',
    password: '',
    email: '',
    termsandservice:false
  }

  // verify signup
  export const verifySignupModel = {
    email: '',
    verificationCode: '',
  }

  // login
  export const loginModel = {
    email: '',
    password: '',
  }

  // google login
  export const googleLoginModel = {
    token: '',
  }

  // forget password otp
  export const fogotOtpModel = {
    email: '',
  }

  // resend forget password otp
  export const resendFogotOtpModel = {
    email: '',
  }

    // forget password otp verify
    export const verifyForgotPassOtpModel = {
      email: '',
      verificationCode: '',
    }

  // profile
  export const profileModel = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }

  // reset password
  export const resetPasswordModel = {
    newPassword: ''
  }