import React, { useState, useEffect, useRef } from 'react';
import { w3cwebsocket as Socket } from 'websocket';
import useAuth from '../Hooks/useAuth';
import useApiCalls from '../Hooks/useApiCalls';
import { getSegment, getLatestSegmentId, profilePicture } from '../Shared/helper';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManagerSuccess, NotificationManagerError } from '../utils/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

const ChatRight = ({ getChatHistory, chatId, setChatId, chatSegmentId, setChatSegmentId }) => {
  const authCtx = useAuth();
  const api = useApiCalls();
  const navigate = useNavigate();
  const [myMessage, setMyMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [client, setClient] = useState(null);
  const location = useLocation();
  const [image, setImage] = useState(undefined);
  const id = new URLSearchParams(location.search).get('id');
  const chatContainerRef = useRef(null);
  const [dots, setDots] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isdailyLimit, setIsdailyLimit] = useState(false);

  const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  const [isListening, setIsListening] = useState(false);
  const [speechEnabled, setSpeechEnabled] = useState(false); // State to manage speech toggle

  const VolumeIcon = ({ isMuted }) => (
    <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
  );

  // Handle Speech Recognition Results
  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: transcript }]);
    sendRecognizedMessage(transcript);
  };

  const sendRecognizedMessage = (message) => {
    if (isdailyLimit) {
      return;
    }
    if (isSending) return; // Prevent sending if already in progress

    console.log('Sending recognized message:', message);
    setIsSending(true); // Block sending of new messages
    setDots(true);

    if (client && client.readyState === WebSocket.OPEN) {
      const payload = {
        type: 'postChat',
        userId: authCtx.user.userId,
        chatId: chatId,
        chatSegmentId: chatSegmentId,
        userInput: message,
      };
      client.send(JSON.stringify(payload));
    } else {
      console.error('WebSocket is not open or has been closed.');
      setIsSending(false); // Unblock if sending failed
    }

    getChatHistory();
  };

  recognition.onspeechend = () => {
    setIsListening(false);
    recognition.stop();
  };

  recognition.onerror = (event) => {
    setIsListening(false);
    console.error(event.error);
  };

  // Toggle Listening
  const toggleListening = () => {
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.start();
      setIsListening(true);
    }
  };

  const toggleSpeech = () => {
    setSpeechEnabled(!speechEnabled); // Toggle speech on or off
  };

  const audioContext = new (window.AudioContext || window.AudioContext)();
  let source = null;
  let audioBufferQueue = [];
  let isPlaying = false;

  const bufferThreshold = 1; // Number of seconds to buffer before starting playback
  let totalBufferedDuration = 0; // Total duration of buffered audio in seconds

  const playAudio = (arrayBuffer) => {
    // Decode the received audio data
    audioContext.decodeAudioData(arrayBuffer, (buffer) => {
      audioBufferQueue.push(buffer);
      totalBufferedDuration += buffer.duration;

      // Check if we have buffered enough audio to start playing
      if (!isPlaying && totalBufferedDuration >= bufferThreshold) {
        isPlaying = true;
        playNextBuffer(); // Start playing if we have enough buffered data
      }
    }, (e) => {
      console.error('Error decoding audio data:', e);
    });
  };

  const playNextBuffer = () => {
    if (audioBufferQueue.length > 0) {
      const buffer = audioBufferQueue.shift();
      source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start();

      // Update total buffered duration
      totalBufferedDuration -= buffer.duration;

      // When the current buffer finishes playing, play the next
      source.onended = playNextBuffer;
    } else {
      isPlaying = false; // No more audio to play
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchChatData = async () => {
    if (id) {
      try {
        const res = await api(getSegment + id, 'get', {});
        console.log('Fetched messages:', res.data); // Log fetched data
        console.log('Current messages before update:', messages);
        if (res.data && res.data.length > 0) {
          setMessages(res.data);
          console.log('Updated messages state with fetched data'); // Confirm state update
        } else {
          console.log('No new messages to update');
        }
        setChatId(id);
        const latestSeg = await api(getLatestSegmentId + id, 'get', {});
        setChatSegmentId(latestSeg.data.latestSegmentId);
      } catch (error) {
        console.error('Error in getting data: ', error);
      }
    }
  };

  useEffect(() => {
    fetchChatData();
  }, [id, fetchTrigger]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const res = await api(profilePicture, 'get', null, { responseType: 'blob' });
        if (res.data.size === 4) {
          setImage(null);
        } else {
          const imageUrl = URL.createObjectURL(res.data);
          setImage(imageUrl);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };
    fetchImage();
  }, []);

  useEffect(() => {
    // Clear messages or perform other reset actions when chatId changes, especially to an empty value
    if (!chatId) {
      setMessages([]);
      // Any other reset logic here
    } else {
      // Fetch new chat data if chatId is not empty
    }
  }, [chatId, chatSegmentId]); // Depend on chatId and chatSegmentId

  useEffect(() => {
    const newClient = new WebSocket(`wss://therapywithai.com/ws?token=${authCtx.token}`);
    newClient.binaryType = 'arraybuffer'; // Set binary data type for audio processing

    newClient.onopen = () => {
      console.log('WebSocket client connected');
    };

    newClient.onmessage = (message) => {
      if (typeof message.data === 'string') {
        // Handle text-based JSON messages
        console.log('Received WebSocket message:', message.data); // Log incoming message
        const data = JSON.parse(message.data);

        if (data.type === 'chatId') {
          setChatId(data.chatId);
          setChatSegmentId(data.chatSegmentId);
          initiateNewChat(data.chatId);
        } else if (data.hasOwnProperty('error')) {
          alert(data.error);
        } else if (data.type === 'chatResponsePart') {
          setDots(false);
          const role = 'assistant';
          setMessages((prevMessages) => {
            const isContinue = prevMessages.length > 0 && prevMessages[prevMessages.length - 1].role === role;
            if (isContinue) {
              const lastMessage = {
                ...prevMessages[prevMessages.length - 1],
                content: prevMessages[prevMessages.length - 1].content + data.content,
              };
              return [...prevMessages.slice(0, -1), lastMessage];
            } else {
              return [...prevMessages, { content: data.content, role: role }];
            }
          });
        } else if (data.type === 'chatResponseDone') {
          setIsSending(false); // Re-enable the send button
        } else if (data.type === 'dailyMsgLimitReached') {
          setIsdailyLimit(true);
          setIsSending(true);
          setDots(false);
          NotificationManagerError(
            <>
              <a href="https://therapywithai.com/plans-pricing" rel="noopener noreferrer" style={{ color: '#fff' }}>
              You've reached your daily message limit. Click HERE to get unlimited access!
              </a>
            </>
          );
        }
      } else if (message.data instanceof ArrayBuffer) {
        // Handle binary data (audio)
        console.log('Received binary data');
        if (speechEnabled) {
          playAudio(message.data); // Call a function to play the binary audio data
        }
      }
    };

    newClient.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Save the client in state or ref
    setClient(newClient);

    // Cleanup function to close WebSocket when component unmounts
    return () => {
      if (newClient && newClient.readyState === WebSocket.OPEN) {
        newClient.close();
      }
    };
  }, [authCtx.token, speechEnabled]); // Ensure this effect runs only once or when authCtx.token or speechEnabled changes

  // Initiate new chat
  const initiateNewChat = (id) => {
    navigate('/chat?id=' + id, { replace: true });
  };

  const onSend = () => {
    if (isdailyLimit || !myMessage.trim()) {
      return;
    }
    if (isSending) return; // Prevent sending if already in progress

    console.log('Sending message:', myMessage);
    setIsSending(true); // Block sending of new messages
    setDots(true);

    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: myMessage }]);

    if (client && client.readyState === WebSocket.OPEN) {
      const payload = {
        type: 'postChat',
        userId: authCtx.user.userId,
        chatId: chatId,
        chatSegmentId: chatSegmentId,
        userInput: myMessage,
      };
      client.send(JSON.stringify(payload));
    } else {
      console.error('WebSocket is not open or has been closed.');
      setIsSending(false); // Unblock if sending failed
    }

    setMyMessage(''); // Clear the message input only after sending
    getChatHistory();
  };

  return (
    <>
      <div className="chatRight-bar">
        <div className="chat-main" ref={chatContainerRef} style={{ maxHeight: '600px', overflowY: 'auto' }}>
          {messages.map((message, index) => (
            <div className={`user-chat-single-list-wrap ${message.role === 'user' ? 'client-box' : 'bot-box'}`} key={index}>
              <div className="d-flex align-items-center user-chat-single-list">
                {message.role === 'user' ? (
                  <>
                    <div className="chat-box">{message.content}</div>
                    <img
                      src={image ? image : '/assets/images/ac-user-img.jpg'}
                      alt=""
                      className="img-user"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/images/ac-user-img.jpg';
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/assets/images/chat-logo.png"
                      alt=""
                      className="img-user"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/images/ac-user-img.jpg';
                      }}
                    />
                    <div className="chat-box">{message.content}</div>
                  </>
                )}
              </div>
            </div>
          ))}
          {dots ? (
            <div className="user-chat-single-list-wrap bot-box">
              <div className="d-flex align-items-center user-chat-single-list">
                <img src="/assets/images/chat-logo.png" alt="" className="img-user" />
                <div className="three-dots-wrap">
                  <div id="darkblue" className="position-relative">
                    <span className="loading"></span>
                    <span className="loading"></span>
                    <span className="loading"></span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="typing-chat">
          <div className="d-flex align-items-center chat-typing">
            <input
              type="text"
              className="form-control"
              value={myMessage}
              onChange={(e) => setMyMessage(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && onSend()}
              placeholder="Type a message"
            />
            <button onClick={onSend} type="button" className="chat-send" disabled={isSending || !myMessage.trim()}>
              <img src="/assets/images/send.png" alt="" />
            </button>
            <button
              onClick={toggleListening}
              type="button"
              className={`mic-button ${isListening ? 'listening' : ''}`}
              style={{ marginLeft: '5px' }}
            >
              🎤
            </button>
            <span className={`listening-indicator ${isListening ? 'active' : ''}`}>🔴 Listening...</span>
            <button onClick={toggleSpeech} type="button" className="toggle-speech" style={{ marginLeft: '0px' }}>
              <FontAwesomeIcon icon={speechEnabled ? faVolumeUp : faVolumeMute} />
            </button>
          </div>
        </div>
      </div>
      <style>
        {`
          .mic-button{
            margin: 0 5px;
          }
          .mic-button,
          .toggle-speech {
            padding: 7px;
            font-size: 18px;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            background: #fff;
          }
          .mic-button.listening,
          .toggle-speech.enabled {
            color: red;
          }
          .listening-indicator {
            display: none;
            color: red;
            margin-left: 10px;
          }
          .listening-indicator.active {
            display: inline;
          }
        `}
      </style>
    </>
  );
};
export default ChatRight;
