import React from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from "../../Hooks/useAuth";

function Banner() {
    const authCtx = useAuth();
    return (
        <section className="slider-wrap">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 order-md-2">
                        <div className="bannPic">
                            <img src="/assets/images/bannHome.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 order-md-1">
                        <div className="bannContent">
                            <h1>
                                Your <span>Free</span>
                                <br />
                                AI Therapist
                            </h1>
                            <p>Redefining Therapy for the Digital Age</p>
                            { authCtx.isLoggedIn ? (
                                <NavLink to="/chat" className="btn-arrow"> <div>Start Chat</div><span>
                                    <i className="fas fa-arrow-right" /></span><div className='in-btn-div'></div></NavLink>
                            ):(
                                <NavLink to="/login" className="btn-arrow"> <div>Start Chat</div><span>
                                    <i className="fas fa-arrow-right" /></span><div className='in-btn-div'></div></NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Banner