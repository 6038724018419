import { useState,createContext } from "react";
import useLogout from "../Hooks/useLogout";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext({
    token:'',
    user:'',
    isLoggedIn:false,
    login: (data) => {},
    logout : () => {},
    savetoken : () => {},
    saveuser : () => {},
})

export const AuthContextProvider = ({children}) => {
    // const logoutUser = useLogout();
    const [token,setToken] = useState(JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')) : null);
    const [user,setUser] = useState(JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null);

    const userIsLoggedIn = !!token;

    const loginhandler = (data) => {
        setToken(data.token);
        const userData = jwtDecode(data.token);
        setUser(userData);
        localStorage.setItem('tokens',JSON.stringify(data.token))
        localStorage.setItem('user',JSON.stringify(userData))
    }
    const logouthandler = async () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('tokens');
        localStorage.removeItem('user');
        localStorage.removeItem('uemail');
        // await logoutUser(token.refresh.token);
    }
    const contextValues = {
        token:token,
        user:user,
        isLoggedIn:userIsLoggedIn,
        login:loginhandler,
        logout:logouthandler,
        savetoken:setToken,
        saveuser:setUser
    }
    return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>
}
export default AuthContext;