import React from 'react'
import Banner from '../components/home/Banner'
import Features from '../components/home/Features'
import Privacy from '../components/home/Privacy'
import Headers from '../components/Header'
import Slider from '../components/home/Slider'
import Footer from '../components/home/Footer'
import { useLoader } from '../context/LoaderContext';
import { useEffect } from 'react'
import Blogsection from '../components/home/BlogSection'


function Home() {
    const { startLoading, stopLoading } = useLoader();

    useEffect(() => {
      startLoading();
      setTimeout(() => {
        stopLoading();
      }, 1000);
    }, []);
    return (
      <div className="homeBg">
          <Headers />
          <Banner />
          <Features />
          <Blogsection />
          <Slider />
          <Privacy/>
          <Footer />
      </div>
    )
}
export default Home