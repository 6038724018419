import { useLocation,  Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

//routes
import Home from "../pages/Home";
import Login from "../pages/auth/Login";
import Resgistation from "../pages/auth/Resgistation";
import ForgetPassword from "../pages/auth/ForgetPassword";
import NewPassword from "../pages/auth/NewPassword";
import Account from "../pages/auth/Account";
import VerefyOtp from "../pages/auth/VerefyOtp";
import VerefySignupOtp from "../pages/auth/VerifySignupOtp";
import PlansPricing from "../pages/subscription/PlansPricing";
import AfterLoginRedirectRoutes from './AfterLoginRedirectRoutes';
import PrivateRoutes from './PrivateRoute';
import Chat from "../pages/Chat/Chat";
import Blogs from "../pages/Blogs/Blogs";
import BlogDetail from "../pages/Blogs/BlogDetails";


function SiteRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>

            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/blogs/:slug" element={<BlogDetail />} />
            <Route exact path="/resgistation" element={<Resgistation />} />
            <Route exact path="/verify-otp" element={<VerefyOtp />} />
            <Route exact path="/verify-signup-otp" element={<VerefySignupOtp />} />
            <Route exact path="/forget-password" element={<ForgetPassword />} />
            <Route exact path="/new-password" element={<NewPassword />} />
            <Route exact path="/plans-pricing" element={<PlansPricing />} />

           { /* <Route element={<AfterLoginRedirectRoutes />}>
              <Route exact path="/reset-password/:token" element={<ResetPassword />}/>
              <Route exact path="/activate/:token" element={<ActivateAccount />}/>
             </Route> */ }

            <Route element={<PrivateRoutes />}>
                <Route exact path="/account" element={<Account />} />
                <Route exact path="/chat/" element={<Chat />} />
                {/* <Route path="/chat" element={<Chat key={location.key} />} /> */}

                
            </Route>
            { /*<Route path="*" element={<NotFOundPAge />} /> */ }
          </Routes>

        </AnimatePresence>
    );
  }

export default SiteRoutes;