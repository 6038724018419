import { Outlet,Navigate } from "react-router-dom"
import useAuth from "../Hooks/useAuth";


function AfterLoginRedirectRoutes() {
    const authCtx = useAuth();
    return (
      authCtx.isLoggedIn ? <Navigate to={"/"} /> : <Outlet />
    )
}

export default AfterLoginRedirectRoutes