import React from "react";
import ChatLeft from "../../components/ChatLeft";
import Header from "../../components/Header";
import ChatRight from "../../components/ChatRight";
import { useState, useEffect } from "react";
import { useLoader } from '../../context/LoaderContext';
import { getAllChat } from "../../Shared/helper";
import useApiCalls from "../../Hooks/useApiCalls";
import { useLocation } from 'react-router-dom';

const Chat = () => {
  const location = useLocation();

  const [showChatLeft, setShowChatLeft] = useState(false);
  const [showChatRight, setShowChatRight] = useState(false);
  let [chatHistory, setChatHistory] = useState([]);
  const [chatId, setChatId] = useState('');
  const [chatSegmentId, setChatSegmentId] = useState('');
  const [isInitialChatHistoryLoaded, setIsInitialChatHistoryLoaded] = useState(false);
  const [isChatHistoryLoadAttempted, setIsChatHistoryLoadAttempted] = useState(false);


  const api = useApiCalls();
  const { startLoading, stopLoading } = useLoader();

  useEffect(() => {
    startLoading();
    setTimeout(() => {
      stopLoading();
    }, 1000);
  }, []);

  useEffect(() => {
    // Ensure chatRight is visible, chatLeft is not, and messages are empty
    if (!showChatLeft && showChatRight) {
      // Scrolls to the bottom of the page
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
  
      // Focuses on the input box if present
      const inputBox = document.querySelector('.typing-chat .form-control');
      if (inputBox) {
        inputBox.focus();
      }
    }
  }, [isInitialChatHistoryLoaded]);
  

   // Get chat history
   const getChatHistory = async () => {
     // Guard to ensure loading is attempted only once
      try {
        const res = await api(getAllChat, 'get', {});
        setChatHistory(res.data['chats']);
        if (!isChatHistoryLoadAttempted) { 
          setIsInitialChatHistoryLoaded(true);
          setIsChatHistoryLoadAttempted(true); // Mark that we've attempted to load the chat history
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
  };

  useEffect(() => {
    console.log("compounted mounted, the getChatHistory useEffect is called");
    getChatHistory();
  }, []); // Dependency array remains empty to run only on component mount

  useEffect(() => {
    console.log("the handleVisibility useEffect is called")
    const handleVisibility = () => {
      console.log("handleVisiblity called!");
      const isNarrow = window.innerWidth < 767;
      const noChats = chatHistory.length === 0;
      setShowChatLeft(!isNarrow || !noChats);
      setShowChatRight(!isNarrow || (isNarrow && noChats));
    };

    // Initial visibility check
    handleVisibility();

    // Handle resize
    const handleResize = () => {
      handleVisibility();
    };

    //window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isInitialChatHistoryLoaded]);

  // consider trying this solution:
  //Check for Actual Size Changes: Store the window's width and height at the time of the last handleVisibility invocation. On a resize event, compare the current window size to these stored values. If they haven't changed, don't proceed with calling handleVisibility.
//   useEffect(() => {
//     let lastWidth = window.innerWidth;
//     let lastHeight = window.innerHeight;

//     const handleResize = () => {
//         if (window.innerWidth === lastWidth && window.innerHeight === lastHeight) {
//             return; // Size hasn't changed, likely a scroll-induced resize event
//         }
//         lastWidth = window.innerWidth;
//         lastHeight = window.innerHeight;
//         handleVisibility();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
// }, [isInitialChatHistoryLoaded]); // Dependencies

  const handleInitiateNewChat = () => {
    if (window.innerWidth <= 767) {
      setShowChatLeft(false);
      setShowChatRight(true);
    } else {
      setShowChatRight(true);
    }
  };

  return (
    <>
      <div className="page-bg chat">
      <Header />
      <div className="chat-sec position-relative">
       <div className="container">
       <div className="row">
          {showChatLeft && (
            <div className="col-lg-4">
              <ChatLeft 
              onInitiateNewChat={handleInitiateNewChat} 
              chatHistory={chatHistory}  
              getChatHistory={getChatHistory} 
              chatId={chatId}
              setChatId={setChatId}
              setChatSegmentId={setChatSegmentId}
              />
            </div>
          )}
          {showChatRight && (
            <div className="col-lg-8">
              <ChatRight 
                getChatHistory={getChatHistory}
                showChatLeft={showChatLeft}
                showChatRight={showChatRight}
                isInitialChatHistoryLoaded={isInitialChatHistoryLoaded}
                chatId={chatId}
                setChatId={setChatId}
                chatSegmentId={chatSegmentId}
                setChatSegmentId={setChatSegmentId}
                />
            </div>
          )}
        </div>
       </div>
      </div>
      </div>
    </>
  );
};
export default Chat;


// import React from "react";
// import ChatLeft from "../../components/ChatLeft";
// import Header from "../../components/Header";
// import ChatRight from "../../components/ChatRight";
// import { useState, useEffect } from "react";
// import { useLoader } from '../../context/LoaderContext';
// import { getAllChat } from "../../Shared/helper";
// import useApiCalls from "../../Hooks/useApiCalls";
// import { useLocation } from 'react-router-dom';

// const Chat = () => {
//   const location = useLocation();

//   const [showChatLeft, setShowChatLeft] = useState(false);
//   const [showChatRight, setShowChatRight] = useState(false);
//   const [chatHistory, setChatHistory] = useState([]);  // Ensure chatHistory is always initialized as an empty array
//   const [chatId, setChatId] = useState('');
//   const [chatSegmentId, setChatSegmentId] = useState('');
//   const [isInitialChatHistoryLoaded, setIsInitialChatHistoryLoaded] = useState(false);
//   const [isChatHistoryLoadAttempted, setIsChatHistoryLoadAttempted] = useState(false);

//   const api = useApiCalls();
//   const { startLoading, stopLoading } = useLoader();

//   useEffect(() => {
//     startLoading();
//     setTimeout(() => {
//       stopLoading();
//     }, 1000);
//   }, []);

//   useEffect(() => {
//     if (!showChatLeft && showChatRight) {
//       window.scrollTo({
//         top: document.body.scrollHeight,
//         behavior: 'smooth',
//       });

//       const inputBox = document.querySelector('.typing-chat .form-control');
//       if (inputBox) {
//         inputBox.focus();
//       }
//     }
//   }, [isInitialChatHistoryLoaded]);

//   const getChatHistory = async () => {
//     try {
//       const res = await api(getAllChat, 'get', {});
//       if (res && res.data && res.data['chats']) {
//         setChatHistory(res.data['chats']);
//       } else {
//         setChatHistory([]); // Ensure chatHistory is an empty array if there's no data
//       }
//       if (!isChatHistoryLoadAttempted) { 
//         setIsInitialChatHistoryLoaded(true);
//         setIsChatHistoryLoadAttempted(true);
//       }
//     } catch (error) {
//       console.error('Error fetching chat history:', error);
//       setChatHistory([]); // Ensure chatHistory is never undefined
//     }
//   };

//   useEffect(() => {
//     console.log("Component mounted, the getChatHistory useEffect is called");
//     getChatHistory();
//   }, []);

//   useEffect(() => {
//     console.log("The handleVisibility useEffect is called");

//     const handleVisibility = () => {
//       console.log("handleVisibility called!");

//       if (!chatHistory) {
//         setShowChatLeft(false);
//         setShowChatRight(true);
//         return;
//       }

//       const isNarrow = window.innerWidth < 767;
//       const noChats = chatHistory.length === 0;
//       setShowChatLeft(!isNarrow || !noChats);
//       setShowChatRight(!isNarrow || (isNarrow && noChats));
//     };

//     // Initial visibility check
//     handleVisibility();

//     // Handle resize
//     const handleResize = () => {
//       handleVisibility();
//     };

//     //window.addEventListener('resize', handleResize);

//     return () => window.removeEventListener('resize', handleResize);
//   }, [isInitialChatHistoryLoaded]);

//   const handleInitiateNewChat = () => {
//     if (window.innerWidth <= 767) {
//       setShowChatLeft(false);
//       setShowChatRight(true);
//     } else {
//       setShowChatRight(true);
//     }
//   };

//   return (
//     <>
//       <div className="page-bg chat">
//         <Header />
//         <div className="chat-sec position-relative">
//           <div className="container">
//             <div className="row">
//               {showChatLeft && (
//                 <div className="col-lg-4">
//                   <ChatLeft 
//                     onInitiateNewChat={handleInitiateNewChat} 
//                     chatHistory={chatHistory}  
//                     getChatHistory={getChatHistory} 
//                     chatId={chatId}
//                     setChatId={setChatId}
//                     setChatSegmentId={setChatSegmentId}
//                   />
//                 </div>
//               )}
//               {showChatRight && (
//                 <div className="col-lg-8">
//                   <ChatRight 
//                     getChatHistory={getChatHistory}
//                     showChatLeft={showChatLeft}
//                     showChatRight={showChatRight}
//                     isInitialChatHistoryLoaded={isInitialChatHistoryLoaded}
//                     chatId={chatId}
//                     setChatId={setChatId}
//                     chatSegmentId={chatSegmentId}
//                     setChatSegmentId={setChatSegmentId}
//                   />
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Chat;
