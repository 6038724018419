import React from 'react'

function Features() {
    return (
        <section className="featureSec sec-gapTop sec-gapBottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="featureTitle heading1 mb-lg-5 mb-md-4 mb-3">
                                <h2>Features</h2>
                            </div>
                        <div className="featurePic">
                            <img src="/assets/images/lotus.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="featureContent">
                            <div className="featureTitle heading2 mb-lg-5 mb-md-4 mb-3">
                                <h2>Features</h2>
                            </div>
                            <div className="features-list">
                                <ul>
                                    <li>
                                        <div className="features-list-icon">
                                            <img src="/assets/images/feature-icon1.png" alt="" />
                                        </div>
                                        <div className="features-list-content">
                                            <h4>
                                                Extremely <span>Affordable</span>
                                            </h4>
                                            <p>Low cost therapy, small fraction of the cost of traditional therapy</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="features-list-icon">
                                            <img src="/assets/images/feature-icon2.png" alt="" />
                                        </div>
                                        <div className="features-list-content">
                                            <h4>
                                                Always <span>Available</span>
                                            </h4>
                                            <p>Access therapy 24/7 whenever and wherever you need</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="features-list-icon">
                                            <img src="/assets/images/feature-icon3.png" alt="" />
                                        </div>
                                        <div className="features-list-content">
                                            <h4>
                                                Personalized <span>Therapist</span>
                                            </h4>
                                            <p>Therapist learns more about you with every conversation</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="features-list-icon">
                                            <img src="/assets/images/feature-icon4.png" alt="" />
                                        </div>
                                        <div className="features-list-content">
                                            <h4>
                                                Multiple <span>Conversations</span>
                                            </h4>
                                            <p>
                                                Manage different conversations simultaneously without effort{" "}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Features