import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useLoader } from "../../context/LoaderContext";
import MediaCard from "../../components/MediaCard";
import { Box } from "@mui/material";
import {cardData} from './data'
import { Link } from "react-router-dom";

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

function cleanTitle(title) {
  return title.replace(/\?$/, ''); // Removes a trailing '?' if it exists
}

const Blogs = () => {
    const { startLoading, stopLoading } = useLoader();

  useEffect(() => {
    startLoading();
    setTimeout(() => {
      stopLoading();
    }, 1000);
  }, []);
  return (
    <>
      <div className="page-bg chat">
      <Header />
        <div className="container">
        <Box
    sx={{
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '35px',
        gap: '30px',
        justifyContent: 'center',
        marginTop: '135px',
        paddingBottom: '135px',
    }}
>
    {cardData.map((ele, index) => (
        <Link
            to={`/blogs/${cleanTitle(slugify(ele.heading))}`}
            style={{ textDecoration: 'none', display: 'flex' }}
            key={index}
        >
            <MediaCard data={ele} style={{ 
              flex: '1 1 300px', 
              boxSizing: 'border-box', 
              minWidth: '300px !important'  // Force the min-width
            }}   />
        </Link>
    ))}
</Box>

        </div>
      </div>
    </>
  );
};
export default Blogs;
