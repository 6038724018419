// BASE URL
export const apiBaseUrl = `https://therapywithai.com`;

// AUTH MODULE
export const signup = `/auth/signup`;
export const verifysignup = `/auth/verify-code/signup`;
export const login = `/auth/login`;
export const googlelogin = `/auth/google`;
export const forgotpassotp = `/auth/reset-password-request`;
export const resendotp = `/auth/resend-verification`;
export const forgotpassverify = `/auth/verify-code/reset-password`;
export const resetPassword = `/auth/reset-password`;
export const logout = `/auth/logout`;
export const refresh = `/auth/refreshurl`;

// PROFILE MODULE
export const account = `/api/account`;
export const uploadPhoto = `/api/account/upload-photo`;
export const profilePicture = `api/account/profile-picture`;
export const deleteAccount = `api/account/delete`;

// CHAT MODULE
export const sendChat = `/ws/chat/post`;
export const getAllChat = `/ai-therapist/chat/all`;
export const getSegment = `/ai-therapist/chat/chatSegmentsAggByChatId/`;
export const getLatestSegmentId = `/ai-therapist/chat/latestSegmentId/`;
export const deleteChat = `/ai-therapist/chat/delete/`; // pass {chatId}
export const renameChatUpdate = `/ai-therapist/chat/rename/`; // pass {chatId}

// SUBSCRIPTION MODULE
export const subsapi = `/subscriptions`;

