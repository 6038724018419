import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "./common/Button";
import MobileSliderMonthly from "./MobileSliderMonthly";
import MobileSliderAnually from "./MobileSliderAnually";
import { SubscriptionButtton } from "../pages/subscription/subscriptionButton";
import useApiCalls from "../Hooks/useApiCalls";
import { subsapi } from "../Shared/helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const api = useApiCalls();

  const fetchSubscriptionDetails = async () => {
    try {
      const res = await api(subsapi, 'get', {});
      setSubscriptionDetails(res.data);
      setActivePlan(res?.data?.data?.planId);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  };
  
  useEffect(() => {
    fetchSubscriptionDetails();
  }, [activePlan]);

  const getDefaultPlan = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to cancel your subscription?");
      if (!isConfirmed) {
        return;
      }
      const res = await api(subsapi, 'delete', {});
      if(res.status === 200){
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error in delete subscription:', error);
    }
  }
  
  return (
    <div className="tab-main-wrap pri-tab desk-tab">
   <Box sx={{ width: "100%" }}>
      <Box className="tab-main" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="tab-name"
        >
          <Tab className="tab-name" label="Monthly" {...a11yProps(0)} />
          <Tab className="tab-name" label=" Annual" {...a11yProps(1)} />
          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Free Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>0.00/Month{" "}
              </div>
              <p className="text-center m-text">Free</p>
              {activePlan ? (
                <button type='button' className="btn w-100" onClick={getDefaultPlan}>Get Default Plan</button>
              ) : (
                <Button title={"Default Plan"} className={"w-100"} />
              )}
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 10 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <div className="tab-col">
              <h3>Therapy Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>9.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Monthly</p>
               
                <div className="button-pay-wrap">
                
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Standard" 
                price="9.99" 
                planId="P-9CA862291T8724350MXUXQ7Q"
                subscriptionDetails={subscriptionDetails}/>
                </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Expert Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>14.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Monthly</p>

              <div className="button-pay-wrap">
                <SubscriptionButtton 
                  activePlan={activePlan} 
                  setActivePlan={setActivePlan}
                  title="Expert Therapist" 
                  price="14.99" 
                  planId="P-3VL628773U860484SMXKOWNY" 
                  subscriptionDetails={subscriptionDetails}/>
                </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 25 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Expert Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>19.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Monthly</p>
                
                <div className="button-pay-wrap">
                <SubscriptionButtton 
                  activePlan={activePlan} 
                  setActivePlan={setActivePlan}
                  title="Expert Unlimited" 
                  price="19.99" 
                  planId="P-5BW13521V02354646MXKOXJA"
                  subscriptionDetails={subscriptionDetails} />
                </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="for-mobile-tab">
        <MobileSliderMonthly />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Free Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>0.00/Month{" "}
              </div>
              <p className="text-center m-text">Free</p>
              {activePlan ? (
                <button type='button' className="btn w-100" onClick={getDefaultPlan}>Get Default Plan</button>
              ) : (
                <Button title={"Default Plan"} className={"w-100"} />
              )}
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 10 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <div className="tab-col">
              <h3>Therapy Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>7.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Therapy Unlimited" 
                price="7.99" 
                planId="P-2MB80876AU170761TMXU7G2Y"
                subscriptionDetails={subscriptionDetails} />
                </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Expert Therapist</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>12.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                
              <SubscriptionButtton 
              activePlan={activePlan} 
              setActivePlan={setActivePlan}
              title="Expert Therapist" 
              price="12.99" 
              planId="P-3TL16085DN246563VMXKO2GI"
              subscriptionDetails={subscriptionDetails} />
              </div>
                
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Up to 25 Messages a Day
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="tab-col">
              <h3>Expert Unlimited</h3>
              <div className="sub-text">
                {" "}
                <sup>$</sup>16.99/Month{" "}
              </div>
              <p className="text-center m-text">Billed Annually</p>
              <div className="button-pay-wrap">
                <SubscriptionButtton 
                activePlan={activePlan} 
                setActivePlan={setActivePlan}
                title="Expert Unlimited" 
                price="16.99" 
                planId="P-11F22732MX5592719MXKO3IA" 
                subscriptionDetails={subscriptionDetails}/>
                </div>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Unlimited Messages
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Available 24/7
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Confidential Conversations
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Retains Detailed Conversation History
                </li>
                <li>
                  <span>
                    <img src="/assets/images/success.png" alt="" />
                  </span>
                  Enhanced Personlization Over Time
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="for-mobile-tab">
        <MobileSliderAnually />
        </div>
      </CustomTabPanel>
    </Box>
    </div>
  );
}
