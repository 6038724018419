import React, { useState } from "react";
import axios from "axios";
import { apiBaseUrl } from "../../Shared/helper";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "../../components/common/Button";
import { resetValidatorSchema } from "../../Validators/resetValidatorSchema";
import { resetPasswordModel } from "../../Shared/model";
import useAuth from "../../Hooks/useAuth";
import { NotificationManagerError, NotificationManagerSuccess } from "../../utils/Notification";

const NewPassword = () => {
    const resetPasswordEndpoint = `${apiBaseUrl}/auth/reset-password`;
    const [newPasswordVisible, setPasswordvisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordvisible] = useState(false); 
    const navigate = useNavigate();
    const authCtx = useAuth();
    const  { values, errors, touched, handleSubmit, handleChange, handleBlur} = useFormik({
        initialValues: resetPasswordModel,
        validationSchema: resetValidatorSchema,
        onSubmit: async(value) => {
        axios.post(resetPasswordEndpoint, {
          newPassword: value.newPassword,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authCtx.token}`
          }
        })
        .then(res => {
          if(res.status === 200){
            NotificationManagerSuccess(res?.data?.message);
            navigate('/account', {replace: true});
          }else{
            NotificationManagerError(res?.data?.message);
          }
        })
        .catch(error => {
          NotificationManagerError(error);
          console.log(error);
        });
    }
  })

    return (
        <div className="page-bg">
        <Header />
        <div className="login-page-flex">
          <div className="login-page-flex-main w-100">
          <div className="login-page">
            <h1 className="text-center">Create new password</h1>
            <p className="text-center mb-4 mt-2">Your new password must be different from previous used password</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group position-relative">
               <div className="position-relative">
               <span className="icon-container">
                    <FontAwesomeIcon icon="fa-solid fa-lock" />
                </span>
                <input
                  type={ newPasswordVisible? "text": "password" }
                  className="form-control"
                  placeholder="Password"
                  name="newPassword"
                  id="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                />
                 <span className="password-show-hide">
                <FontAwesomeIcon icon={!newPasswordVisible ? 'fa fa-eye' : 'fa-eye-slash'} 
                onClick={() => { setPasswordvisible((pre) => !pre); }} />
                </span>
               </div>
                {errors.newPassword && touched.newPassword ? (
                  <span className="error-msg">{errors.newPassword}</span>
                  ) : null}
               
              </div>
              <div className="form-group position-relative">
              <div className="position-relative">
                <span className="icon-container">
                    <FontAwesomeIcon icon="fa-solid fa-lock" />
                </span>
                <input
                  type={confirmPasswordVisible ? "text": "password" }
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  id="confirmpassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmpassword}
                />
                 <span className="password-show-hide">
                <FontAwesomeIcon icon={!confirmPasswordVisible ? 'fa fa-eye' : 'fa-eye-slash'} 
                onClick={() => { setConfirmPasswordvisible((pre) => !pre); }} />
                </span>
                </div>
                { errors.confirmpassword && touched.confirmpassword ? (
                  <span className="error-msg">{errors.confirmpassword}</span>
                  ) : null}
              </div>
              <Button type={"submit"} title={"Reset Password"} className={"w-100"} />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}
export default NewPassword;
