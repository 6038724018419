import { toast } from "react-toastify";


const config = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  export const NotificationManagerError = (content) => {
    // Check if 'content' is a string, if so, use it directly
    if (typeof content === 'string') {
      toast.error(content, config);
    } else {
      // If 'content' is not a string, it's assumed to be a React component
      toast.error(<>{content}</>, config);
    }
  };

export const NotificationManagerInfo = (msg) => {
    toast.info(msg,config)
}

export const NotificationManagerSuccess = (msg) => {
    toast.success(msg,config)
}

export const NotificationManagerWarning = (msg) => {
    toast.warning(msg,config)
}