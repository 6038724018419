import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import useApiCalls from "../../Hooks/useApiCalls";
import { account, uploadPhoto, profilePicture } from "../../Shared/helper";
import { NotificationManagerSuccess, NotificationManagerError } from "../../utils/Notification";
 
const Account = () => {
  const api = useApiCalls();
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api( account, 'get', {});
        setData(res.data.account);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[])

  useEffect(() => {
    const fetchImage = async () => {
      try {
        // Adjusting the API call to include responseType in the options
        // Assuming `apicallPrivate` directly utilizes Axios or a similar mechanism
        // that can handle responseType: 'blob'
        const res = await api(profilePicture, 'get', null, {
          responseType: 'blob'
        });
        // Assuming the API call is successful and returns a blob
        if (res.data) {
          const imageUrl = URL.createObjectURL(res.data); 
          setImage(imageUrl);
        } else {
          // Handle case where response is not as expected
          console.error('Invalid response data');
          setImage("/assets/images/ac-user-img.jpg"); // Fallback image
        }
      } catch (error) {
        console.error('Error fetching image:', error);
        setImage("/assets/images/ac-user-img.jpg"); // Fallback image
      }
    };
    fetchImage();
}, []);
  

const handleImageChange = async (e) => {
  e.preventDefault();
  const file = e.target.files[0];
  console.log(file); // Check if the file object looks correct
  if (file) {
    try { 
      const formData = new FormData();
      formData.append("image", file);

      // Log formData for debugging
      for (let [key, value] of formData.entries()) { 
        console.log(key, value);
      }

      const uploadRes = await api(uploadPhoto, 'post', formData);

      if(uploadRes.status === 200){
        NotificationManagerSuccess(uploadRes?.data?.message);
        setImage(uploadRes.data.imageUrl);
      }else{
        NotificationManagerError(uploadRes?.data?.message);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      NotificationManagerError("Failed to upload image.");
    }
  }
};

  const ChangePic = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const navigate = useNavigate();
  const authCtx = useAuth();

  const { values, errors, touched, handleSubmit, handleBlur, handleChange, setValues } = useFormik({
      initialValues: {
        username: data?.username,
        email: data?.email,
        dailymessage: data?.subscriptionPlan?.dailyMessageLimit,
        planname: data?.subscriptionPlan?.name,
      },
      onSubmit: async(values) => {}
  })

  useEffect(() => {
    setValues({
      username: data?.username,
      email: data?.email,
      dailymessage: data?.subscriptionPlan?.dailyMessageLimit,
      planname: data?.subscriptionPlan?.name,
    });
  }, [data, setValues]);

  const handleLogout = () => {
    authCtx.logout();
    navigate("/", { replace: true });
  };

  const handleDeleteAccount = async() => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete your account?");
      if (!isConfirmed) {
        return;
      }
      const res = await api(account, 'delete', {});
      if(res.status === 200){
        authCtx.logout();
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error('Error in delete account:', error);
    }
  }

  const handleRedirection = () => {
    navigate("/plans-pricing");
  }

  return (
    <>
      <div className="page-bg">
        <Header profileImage={image}/>
        <div className="login-page-flex profile-page">
          <div className="login-page-flex-main w-100">
            <div className="login-page">
              <div className="profile-img"> 
                <img src={ image ? image : "/assets/images/ac-user-img.jpg"} alt=""onError={(e) => { e.target.onerror = null; e.target.src="/assets/images/ac-user-img.jpg"; }}/>
              </div>
              <div className="file-up position-relative mb-4">
                <img src="/assets/images/img.png" alt="" />
                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <button type="button" className="up-pic" onClick={ChangePic}>Upload photo</button>
              </div>
              <div className="profile-input">
                <div className="from-group">
                  <label htmlFor="Username">Username</label>
                  <input 
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    onBlur={handleBlur} 
                    onChange={handleChange} 
                    value={values.username} 
                    errors={errors.username} 
                    touched={touched.username}
                    readOnly={true}
                  />
                </div>
                <div className="from-group">
                  <label htmlFor="Name">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    onBlur={handleBlur} 
                    onChange={handleChange} 
                    value={values.email} 
                    errors={errors.email} 
                    touched={touched.email}
                    readOnly={true}
                  />
                </div>
                <div className="from-group">
                  <label htmlFor="Name">Daily Message Limit</label>
                  <input
                    type="text"
                    name="dailymessage"
                    id="dailymessage"
                    className="form-control"
                    onBlur={handleBlur} 
                    onChange={handleChange} 
                    value={values.dailymessage} 
                    errors={errors.dailymessage} 
                    touched={touched.dailymessage}
                    readOnly={true}
                  />
                </div>
                <div className="from-group">
                  <label htmlFor="Name">Current Subscription Plan</label>
                  <input
                    type="text"
                    name="planname"
                    id="planname"
                    className="form-control"
                    onBlur={handleBlur} 
                    onChange={handleChange} 
                    value={values.planname} 
                    errors={errors.planname} 
                    touched={touched.planname}
                    readOnly={true}
                  />
                </div>
                <button type="button" onClick={handleRedirection} className="btn w-100">Change Subscription Plan</button>
                <div className="mt-4 l-out acc-flex">
                  <p className="curser-pointer m-0 w-100 text-left" onClick={handleLogout}><img src="/assets/images/exit.png" alt="" />Log Out</p>
                  <p className="curser-pointer m-0 w-100 text-right" onClick={handleDeleteAccount}><img src="/assets/images/delete.png" alt="" />Delete Account</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
