import * as React from 'react';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

export default function ClickAway({ chatid, chatName, onDelete, onRename }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(chatid);
    setOpen(false);
  };

  const handleRenameClick = (e) => {
    e.stopPropagation();
    onRename(chatid, chatName);
    setOpen(false);
  };

  const styles = {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    p: 1,
    bgcolor: 'background.paper',
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <button type="button" onClick={handleClick}>
          <img src="/assets/images/3dot.png" alt="" />
        </button>
        {open ? (
          <Box sx={styles}>
            <ul className='p-0'>
              <li className='d-flex align-items-center'><img src="/assets/images/write.png" alt="" onClick={handleRenameClick} />
                <p className='mb-0 cursor-pointer' onClick={handleRenameClick}>Rename</p>
              </li>
              <li className='d-flex align-items-center'><img src="/assets/images/delete.png" alt="" onClick={handleDeleteClick} />
                <p className='mb-0 cursor-pointer' onClick={handleDeleteClick}>Delete</p>
              </li>
            </ul>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}