import React from 'react'
import { useEffect } from "react";
import Header from '../../components/Header'
import BasicTabs from '../../components/Tab'
import { useLoader } from '../../context/LoaderContext';


function PlansPricing() {
    const { startLoading, stopLoading } = useLoader();
    useEffect(() => {
      startLoading();
      setTimeout(() => {
        stopLoading();
      }, 1000);
    }, []);
    return (
        <>
            <div className="page-bg">
                <Header />
                <div className='container'>
                <div className='plane-page'>
                    <h1 className='text-center'>Plans & <span>Pricing</span></h1>
                    <p>Choose the Therapy Plan that is right for you! Expert Therapist will provide you with a superior therapeutic expertise and will enable you to truly tackle your issues in depth</p>
                    <BasicTabs />
                </div>
               
                </div>

            </div>
        </>
    )
}

export default PlansPricing