import React, { createContext, useState, useContext } from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoaderContext = createContext();

export const useLoader = () => {
  return useContext(LoaderContext);
};

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const loaderValue = {
    loading,
    startLoading,
    stopLoading,
  };

  return (
    <LoaderContext.Provider value={loaderValue}>
      {children}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url("/assets/images/bannHome-bg.png")',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <RotatingLines
            visible={true}
            height="70"
            width="70"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </LoaderContext.Provider>
  );
};
