import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Slider = () => {
  const options = {
    items: 1,
    dots: false,
    autoplay: false,
    margin: 0,
    nav: true,
    navText:['<i class="fas fa-arrow-left"></i>','<i class="fas fa-arrow-right"></i>'],

    loop: true,
    // margin: 0,
    merge: true,
    // autoplay: true,
    responsive: {
      0: {
        items: 1,
      
       
      },
      600: {
        items: 1,
      
       
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <section className="peopleSec sec-gapTop sec-gapBottom">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-4">
              <div className="featureLeft">
                <h3>
                  What People <span>Say</span>
                </h3>
                <p>
                Read real reviews of people who tested our AI Therapist for themselves!
                </p>
                
              </div>
            </div>
            <div className="col-lg-7 for-d">
              <div className="featureRight">
                <OwlCarousel
                  height={300}
                  className="owl-carousel owl-theme testimonial-owl"
                  {...options}
                >
                  <div className="slider-item">
                    <>
                      <div className="featureRight">
                        <ul>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                            This website made therapy really easy and affordable for me. The ability to use it whenever I needed help working out my issues was a huge help for me. And it is totally free!
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/client-pic1.png"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Janet Westin</h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                            Discovering this AI therapist was a turning point in my journey towards mental well-being. The personalized attention and insightful guidance offered me a new perspective and effective coping strategies. It's remarkable how it blends empathy with cutting-edge technology.
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/male2.jpeg"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Miles Harper</h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  </div>
                  <div className="slider-item">
                    <>
                      {/* Hello world */}
                      <div className="featureRight">
                        <ul>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                            I was skeptical at first, because I didn't think an AI could really perform the role of a therapist, but after I started using it, I was surprised when I found myself in flowing conversations with the AI. Overall, this website is extremely useful.
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/client-pic2.png"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Vicky Walton</h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                            Amazing therapist, and really easy to use. This is the future of therapy.
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/male1.jpeg"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Mike Rivera</h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-lg-12 for-m">
            <OwlCarousel
                  height={300}
                  className="owl-carousel owl-theme testimonial-owl"
                  {...options}
                >
                  <div className="slider-item">
                    <>
                      <div className="featureRight">
                        <ul>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                              If only I had discovered this before dumping 10K on in-person therapy! What a life-saver!
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/male2.jpeg"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Miles Harper</h6>
                              </div>
                            </div>
                          </li>
                          
                        </ul>
                      </div>
                    </>
                  </div>
                 
                  <div className="slider-item">
                    <>
                      <div className="featureRight">
                        <ul>
                          <li>
                            <span className="quote-icon">
                              <img src="/assets/images/quote-icon.png" alt="" />
                            </span>
                            <p>
                              I could never wait for my weekly session with my therapist, something always came up a few days before the session and I had no one to talk to. Really grateful for this.
                            </p>
                            <div className="client-nameRow">
                              <div className="client-pic">
                                <img
                                  src="/assets/images/client-pic1.png"
                                  alt=""
                                />
                              </div>
                              <div className="client-name">
                                <h6>Janet Westin</h6>
                              </div>
                            </div>
                          </li>
                          
                        </ul>
                      </div>
                    </>
                  </div>
                </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
