import { Outlet,Navigate } from "react-router-dom"
import useAuth from "../Hooks/useAuth";


const  PrivateRoutes = () =>  {
  const authCtx = useAuth();
  return (
    authCtx.isLoggedIn ? <Outlet /> : <Navigate to={"/login"} />
  )
}

export default PrivateRoutes
